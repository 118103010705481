.title {
    font-weight: 700;
    font-size: 2.5rem;
    text-transform: uppercase;
    span {
        font-family: 'Lumios';
        font-style: italic;
        font-weight: 400;
        font-size: 3rem;
        color: $green;
        text-transform: lowercase;
        &:hover {
            a {
                transform: scale(1.1) rotate(4deg);
            }
        }
        a {
            color: $green;
            text-decoration: none;
            position: relative;
            @include transit();
            
            &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 0;
                height: 2px;
                background: $green;
            }
        }
        @media (max-width: 992px) {
            display: block;            
        }
    }
}