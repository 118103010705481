.breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    a, p {
        font-weight: 300;
        font-size: 14px;
        margin: 0;
        line-height: 24px;
        white-space: nowrap;
    }
    a {
        text-decoration: none;
        @include transit();
        &:hover {
            color: $green;
        }
    }
    p {
        color: #8A9599;
    }
    &__divider {
        position: relative;
        width: 11px;
        height: 8px;
        margin: 0 10px;

        svg {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            fill: #8A9599;
            transform: scale(-1);
        }

    }
}