.footer {
    background: #17272D;
    padding-top: 43px;
    padding-bottom: 37px;
    .footer__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 576px) {
            justify-content: space-between;
            flex-direction: row;
        }
    }
    .social {
        display: flex;
        margin-top: 20px;
        @media (min-width: 576px) {
            margin-top: 0;
        }
        a {
            margin-right: 15px;
            width: 36px;
            height: 36px;
            &:hover {
                svg {
                    fill: #528800;
                }
            }
            svg {
                @include transit();
                fill: #70B30E;
                width: 100%;
                height: 100%;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .logo {
        p {
            color: #FFF;
            font-weight: 700;
            font-size: 38.6935px;
            line-height: 39px;
            margin: 0;
            span {
                color: #70B30E;
            }
        } 
    }
}

.footer__bottom {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    @media (min-width: 1200px) {
        justify-content: space-between;
        flex-direction: row;    
    }
    a {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $green;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FFF;
        margin: 0;
    }
}