.welcome {
    background: url('../images/background.png') no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 269px;
    display: flex;
    align-items: center;
    padding: 20px 0;
    h2 {
        font-weight: 700;
        font-size: 48px;
        margin: 0;
        margin-top: 20px;
        span {
            display: block;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            margin-top: 10px;
        }
    }
}