.solutions {
    background: #EDF5F8;
    p {
        font-weight: 700;
        font-size: 1.12rem;
        line-height: 1.68rem;
        margin-bottom: 50px;
    }
}
.solutions__content {
    .solutions__item {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
        h3 {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5rem;
            background: #70B30E;
            box-shadow: 0px 0px 23.2px rgba(93, 161, 186, 0.15);
            padding: 8px 40px 8px 20px;
            border-radius: 60px 30px 0px 36px / 0px 30px 0px 0px;
            width: fit-content;
            color: #fff;
            margin: 0;
            max-width: 80%;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            background: #FFFFFF;
            box-shadow: 0px 0px 23.2px rgba(93, 161, 186, 0.15);
            padding: 24px;
            padding-bottom: 38px;
            border-radius: 60px 50px 0px 50px / 0px 50px 0px 50px;
    
            li {
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                &::before    {
                    content: '';
                    min-width: 5px;
                    height: 5px;
                    background: #70B30E;
                    border-radius: 50%;
                    position: relative;
                    left: 0;
                    top: .62rem;
                    margin-right: 10px;
                }
            }
        }
    }
}