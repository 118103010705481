/* Include fonts
   ========================================================================= */

/**
 * Roboto
 */
//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')

// Example include webfont
// @include fontFace('Font name', 'filename', 'font-weight-normal', 'font-style-normal', 'woff2')


// 700 
@include fontFace('geometria', 'geometria_bold', 700, 'normal', 'woff2')
@include fontFace('geometria', 'geometria_bold', 700, 'normal', 'woff')

// 500 
@include fontFace('geometria', 'geometria_medium', 500, 'normal', 'woff2')
@include fontFace('geometria', 'geometria_medium', 500, 'normal', 'woff')

// 300 
@include fontFace('geometria', 'geometria_light', 300, 'normal', 'woff2')
@include fontFace('geometria', 'geometria_light', 300, 'normal', 'woff')

// 400 italic
@include fontFace('Lumios', 'Lumios-Marker', 400, 'italic', 'woff2')
@include fontFace('Lumios', 'Lumios-Marker', 400, 'italic', 'woff')

// 400 normal
@include fontFace('Lumios', 'Lumios-DesignElements', 400, 'normal', 'woff2')
@include fontFace('Lumios', 'Lumios-DesignElements', 400, 'normal', 'woff')