.documents {
    ul {
        margin: 0;
        list-style: none;
        padding: 0;
        margin-top: 40px;
        max-width: 584px;
        width: 100%;
        li {
            background: #FFFFFF;
            box-shadow: 0px 0px 23.2px rgba(93, 161, 186, 0.15);
            border-radius: 60px 30px 0px 30px / 0px 30px 0px 30px;
            padding: 16px;
            margin-bottom: 36px;
            position: relative;
            @media (min-width: 768px) {
                padding: 36px;
                padding-left: 137px;    
                &::before {
                    content: '';
                    position: absolute;
                    left: 36px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 54px;
                    height: 66px;
                    background-image: url("data:image/svg+xml,%3Csvg width='54' height='67' viewBox='0 0 54 67' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2_207)'%3E%3Cpath d='M8 0C3.6 0 0 3.6 0 8V58.7C0 63.1 3.6 66.7 8 66.7H46C50.4 66.7 54 63.1 54 58.7V20.7C54 18.2 53 15.9 51.3 14.1L39.9 2.7C38.2 1 35.8 0 33.3 0H8ZM8 3.3H33.3C34.9 3.3 36.4 3.9 37.5 5L48.9 16.4C50 17.5 50.7 19 50.7 20.6V58.6C50.7 61.2 48.6 63.3 46 63.3H8C5.4 63.3 3.3 61.2 3.3 58.6V8C3.3 5.4 5.4 3.3 8 3.3Z' fill='%2370B30E'/%3E%3Cpath d='M27 19.1001C26.6 19.1001 26.1 19.3001 25.8 19.6001C25.5 19.9001 25.3 20.3001 25.3 20.8001V42.8001L18.6 36.1001C17.9 35.4001 16.9 35.4001 16.2 36.1001C15.5 36.8001 15.5 37.8001 16.2 38.5001L24.1 46.4001C25.6 47.9001 28.1 47.9001 29.6 46.4001L37.5 38.5001C38.2 37.8001 38.2 36.8001 37.5 36.1001C36.8 35.4001 35.8 35.4001 35.1 36.1001L28.4 42.8001V20.8001C28.4 20.4001 28.2 19.9001 27.9 19.6001C27.6 19.3001 27.4 19.1001 27 19.1001Z' fill='%2370B30E'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2_207'%3E%3Crect width='54' height='66.7' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                }
            }
            
            &:last-child {
                margin-bottom: 0;
            }
            p {
                text-align: center;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 12px; 
            }
            a {
                border-radius: 60px 30px 0px 30px / 0px 30px 0px 30px;
                background: #70B30E;
                box-shadow: 0px 0px 23.2px rgba(93, 161, 186, 0.15);
                display: flex;
                width: 100%;
                height: 50px;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                font-weight: 700;
                font-size: 16px;
                line-height: 27px;
                text-decoration: none;
            }
        }
    }
}

.documents__container {
    @media (min-width: 768px) {
        background: url('../images/document.png') no-repeat;
        background-size: contain;
        background-position: center right;    
    }

}