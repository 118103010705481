// Font
$font-family-base: "geometria", sans-serif;
$enable-smooth-scroll: false;

/**
 * Grid system
 * -----------------------------------------
 */

// Grid breakpoints
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
);

// Grid containers
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           1.5rem;
$grid-row-columns:            6;

// Links
//$link-color: $blue;
//$link-decoration:                         none;
//$link-hover-color:                        darken($link-color, 15%);
//$link-hover-decoration:                   underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
//$emphasized-link-hover-darken-percentage: 15%;


/**
 * Spacing
 * ---------------------------------------------
 */
$spacer: 1rem;
$spacers: (
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	10: ($spacer * 0.625), // 10px
	15: ($spacer * 0.9375), // 15px
	20: ($spacer * 1.25), // 20px
	25: ($spacer * 1.5625), // 25px
	30: ($spacer * 1.875), // 30px
	40: ($spacer * 2.5), // 40px
	50: ($spacer * 3.125), // 50px
	60: ($spacer * 3.75), // 60px
	70: ($spacer * 4.375), // 70px
	80: ($spacer * 5), // 80px
	90: ($spacer * 5.625), // 90px
	100: ($spacer * 6.25) // 100px
);

// $negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

/*
 * Color system
 * ---------------------------------------------
 */

$green: #7CB92C;
$primary: #17272D;

/**
 * Buttons
 * --------------------------------------
 */
//$btn-border-radius: 0;
//$btn-border-radius-lg: 0;
//$btn-border-radius-sm: 0;

//$btn-font-size: 0.875rem;
//$btn-font-size-sm: 0.875rem;
//$btn-font-size-lg: 1.125rem;

/**
 * Utilities
 * -----------------------------------------------------
 */
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/utilities";

$utilities: map-merge(
	$utilities,
	(
		// Font weight
		"font-weight": map-merge(
				map-get($utilities, "font-weight"),
			(
				values: map-merge(
					map-get(map-get($utilities, "font-weight"), "values"),
					(medium: 600),
				),
			),
		),
		// Opacity
		"opacity": (
			property: opacity,
			responsive: true,
			values: (
				0: 0,
				25: .25,
				50: .5,
				75: .75,
				100: 1,
			)
		),
		// Font-size
		"font-size": (
			property: "font-size",
			responsive: true,
			class: fz,
			values: (
				10: 10px,
				12: 12px,
				14: 14px,
				16: 16px,
				18: 18px,
			)
		),
		// Max-width
		"max-width": (
			property: max-width,
			responsive: true,
			class: mw,
			values: (
				25: 25%,
				50: 50%,
				75: 75%,
				100: 100%,
			)
		)
	)
);
